html,
body {
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  color: #212121;
  font-family: 'Delivery Normal', 'Source Hans Sans CN Regular', 'Source Hans Sans CN Regular', Myanmar, 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.leaflet-container {
  height: 100%;
  min-height: 360px;
}

#map {
  position: inherit;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.dhlSwitch .MuiSwitch-switchBase.Mui-checked {
  right: 13px;
}

.dhlBox .MuiPaper-root {
  box-shadow: none !important;
  border: none;
}

.innerTable .MuiPaper-root {
  box-shadow: none !important;
  border: none;
  /*overflow: hidden;
  overflow-y: scroll;*/
  /*max-height: 250px;*/
  padding: 0px;
}

.Cell-highlightedText {
  font-size: 14px !important;
}

.Cell-dayOfMonth {
  font-size: 14px !important;
}

.MUIDataTable-responsiveBase {
  max-height: 200px;
}

.schedule-tooltip-content .Content-titleContainer svg {
  opacity: 0;
}

.schedule-tooltip-content.more-days :is(.Content-contentContainer, .Content-text.Content-dateAndTitle) {
  display: none;
}